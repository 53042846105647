const colorDefaultChart = ['#FF7676', '#76C5FF', '#800080', '#FFDC76', '#34A770', '#E31A1A']
export const tableReturInsight = [
  {
    key: 'type_name',
    label: 'Problem',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    thClass: 'text-black',
    class: 'p-1 align-middle',
  },
  {
    key: 'JNE',
    label: 'JNE',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    thClass: 'text-black',
    class: 'p-1 align-middle',
    sortable: true,
  },
  {
    key: 'sicepat',
    label: 'SiCepat',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    thClass: 'text-black',
    class: 'p-1 align-middle',
    sortable: true,
  },
  {
    key: 'idexpress',
    label: 'IDE Express',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    thClass: 'text-black',
    class: 'p-1 align-middle',
    sortable: true,
  },
  {
    key: 'sap',
    label: 'SAP',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    thClass: 'text-black',
    class: 'p-1 align-middle',
    sortable: true,
  },
  {
    key: 'ninja',
    label: 'NINJA',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    thClass: 'text-black',
    class: 'p-1 align-middle',
    sortable: true,
  },
  {
    key: 'j&t',
    label: 'J&T',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    thClass: 'text-black',
    class: 'p-1 align-middle',
    sortable: true,
  },
]

export const chartOptions = {
  colors: colorDefaultChart,
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
    },
  },
  fill: {
    opacity: 1,
  },
  chart: {
    stacked: false,
    height: 350,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    type: 'category',
    categories: [],
    // tickAmount: 14,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  noData: {
    text: 'Halo admin, saat ini sedang tidak ada data',
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
  },
  tooltip: {
    shared: false,
    custom({ dataPointIndex, w }) {
      const datas = w.globals.initialSeries
      let htmlRender = ''

      datas.forEach((value, idx) => {
        const seriesName = w.globals.seriesNames[idx]
        const seriesValue = value.data[dataPointIndex]

        htmlRender += `<div>
                <li style="color: ${colorDefaultChart[idx]}">
                  <span style="color: #222222">${seriesName} : ${seriesValue}</span>
                </li>
              </div>`
      })

      return `<div class="px-1 py-75" style="box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);">${htmlRender}</div>`
    },
  },
}
